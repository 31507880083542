<template>
  <b-container style="margin-top:10em">
    <Clientes id="clientes" />
    <br />
    <br />
    <Footer />
  </b-container>
</template>

<script>
import Clientes from "../components/Clientes";
import Footer from "../components/Footer";
export default {
  name: "ClientesParceiros",
  components: {
    Clientes,
    Footer
  }
};
</script>

<style scoped>
</style>